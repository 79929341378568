export default defineNuxtRouteMiddleware(async (to, from) => {
  const user = await getCurrentUser();
  const userData = await useUserData();

  const requiredPermissions = to.meta.allow || [];

  if (user) {
    // console.log(`userData-${user.uid}`, { user: toRaw(user), userData: toRaw(userData), requiredPermissions: Object.values(requiredPermissions) });

    console.log(`userData-${user.uid}`, userData?.uid);

    useState(`userData-${user.uid}`, () => userData);


    const isGranted = computed(() => requiredPermissions.length > 0 && requiredPermissions.includes(userData?.role || "Guest"));

    // console.log("Permission", { role: userData?.role || "Guest", allowed: Object.values(requiredPermissions), granted: isGranted.value, url: { to, from } });

    if (!isGranted?.value) {
      if (["Super Admin", "Admin"].includes(userData?.role)) {
        return navigateTo("/dashboard");
      }
      if (["Entity Admin", "Enterprise Admin"].includes(userData?.role)) {
        return navigateTo("/entities");
      }
      if (["Assessor"].includes(userData?.role)) {
        return navigateTo("/tasks");
      }

      return navigateTo("/unauthorized");
    }
  } else {
    return navigateTo({
      path: "/login",
      query: {
        redirect: to.fullPath,
      },
    });
  }
});
